// Entry point for the build script in your package.json
import { App } from './app'
import { Figures } from './figures'
// import { ValueParser } from './utils/value_parser'
// import { PercentService } from './utils/percent_service'

window.app = new App()
window.Figures = Figures
window.ValueParser = ValueParser
window.PercentService = PercentService

import './jquery'

// Utility
// import * as strftime from 'strftime'
// import 'moment'

// Rails
import "@hotwired/turbo-rails"
import "./controllers"
// import "trix"
// import "@rails/actiontext"
import "./trix_setup"
import "./turbo_stream_actions"
window.Turbo = Turbo

// UI
import './table_filter'
import './page_activity'
import './tooltips'
import './announcements'
import "@github/relative-time-element"

// Charts
// import './charts/sankey'
import './charts/pie'

// This is ugly, but the sorting library we use doesn't provide a
// way to import these sorting functions in a conventional way
// See: https://github.com/tristen/tablesort/issues/94

import * as Tablesort from 'tablesort'
window.Tablesort = Tablesort;
require('tablesort/src/sorts/tablesort.date.js')
require('tablesort/src/sorts/tablesort.number.js')
import './tablesort'
import ValueParser from './utils/value_parser'
import PercentService from './utils/percent_service'

const beforePrint = function() {
  window.app.tabs.forEach((tab) => { tab.fetchContent() })
  window.app.charts.forEach((chart) => { chart.resize() })
}

const afterPrint = () => {
  window.app.charts.forEach((chart) => { chart.resize() })
}

window.onbeforeprint = beforePrint;
window.onafterprint = afterPrint;
