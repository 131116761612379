export default class PercentService {
  static change = (prior, recent) => {
    let srv = new PercentService(prior, recent)
    return srv.change()
  }

  static fraction = (whole, part) => {
    return new PercentService(whole, part).fraction()
  }

  constructor(original, comparison) {
    this.original = original
    this.comparison = comparison
  }

  get float() {
    return Math.abs(this.comparison / this.original) * 1.0
  }

  change(decimal = 2) {
    if (this.original == 0 && this.comparison == 0) { return 0 }
    if (this.original == 0) { return Number.POSITIVE_INFINITY }

    let base = (this.float - (this.original / Math.abs(this.original)))

    return this.round(base * 100, decimal)
  }

  fraction(decimal = 2) {
    if (this.original == 0) { return 0 }

    return this.round(this.float * 100, decimal)
  }

  ratio(decimal = 2) { this.fraction(decimal) }

  round(num, decimal = 2) {
    let offset = 10 ** decimal

    return Math.round(num * offset) / offset
  }
}
